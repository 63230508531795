import React, { useEffect, useState, CSSProperties } from 'react';
import {
  Routes,
  Route,
  Router
} from 'react-router-dom';
import loadable from "@loadable/component"

const IndexPage = loadable(() => import("./pages/Index.js"));
const TeamPage = loadable(() => import("./pages/Team.js"));
const PartnersPage = loadable(() => import("./pages/Partners.js"));
const ContactPage = loadable(() => import("./pages/Contact.js"));
const AboutPage = loadable(() => import("./pages/About.js"));
const _404Page = loadable(() => import("./pages/404.js"));
import { SpinnerInfinity } from 'spinners-react';


import ScrollToTop from "./components/ScrollToTop.js"

function App() {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => setLoading(false), 2300)
  }, [])
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-black h-screen">
        <div className="p-8 rounded-md shadow-lg">
          <SpinnerInfinity size={90} thickness={99} speed={100} color="rgba(151, 57, 172, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
        </div>
      </div>
    )
  }
  return (
    <>
      <ScrollToTop />
      <Routes basename='/index.html'>
        <Route path="/" exact element={<IndexPage />} />

        <Route path="/team" element={<TeamPage />} />

        <Route path="/partners" element={<PartnersPage />} />

        <Route path="/contact" element={<ContactPage />} />

        <Route path="/about" element={<AboutPage />} />

        <Route path="*" element={<_404Page />} />
      </Routes>
    </>
  );
}

export default App;
